import { App } from 'App'
import { MimoPlayerOptions } from 'Types'
import React from 'react'
import ReactDOM from 'react-dom'
import { mergeOptions } from 'utils/ConfigUtil'
import styles from './styles/index.css?inline'

export const fontScript =
  'https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'

class MimoPlayer extends HTMLElement {
  constructor(options: MimoPlayerOptions = {}) {
    super()

    const useShadowDom = this.getAttributeValue(
      'data-shadowdom',
      (v) => v === 'true'
    )
    const dataCheckoutModalOff = this.getAttributeValue(
      'data-checkout-modal-off',
      (v) => v.toString()
    )
    const cartId = this.getAttributeValue('data-cartid', (v) => v.toString())
    const debug = this.getAttributeValue('data-debug', (v) => v === 'true')
    const liveId = this.getAttributeValue('data-liveid', (v) => v.toString())
    const customerId = this.getAttributeValue('data-customer-id', (v) =>
      v.toString()
    )
    const liveUrl = this.getAttributeValue('data-live-url', (v) => v.toString())
    const configEndpoint = this.getAttributeValue('data-config-endpoint', (v) =>
      v.toString()
    )

    const normalizedOptions = mergeOptions(
      {
        cartId,
        useShadowDom,
        debug,
        liveId,
        customerId,
        liveUrl,
        configEndpoint,
        dataCheckoutModalOff,
      },
      options
    )

    const rootElem = normalizedOptions.useShadowDom
      ? this.attachShadow({
          mode: 'open',
        })
      : this

    ReactDOM.render(<App options={normalizedOptions} />, rootElem)

    this.initStyles(rootElem)
  }

  getAttributeValue<T>(key: string, casting: (v: string) => T): T | undefined {
    const value = this.getAttribute(key)
    if (value === null || value === undefined) {
      return undefined
    }
    return casting(value)
  }

  initStyles(rootElem: Node) {
    const styleElem = document.createElement('style')

    styleElem.innerHTML = styles

    rootElem.appendChild(styleElem)

    this.includeFontScript()
  }

  /**
   * Loads the font-script which needs to be included "outside" the shadow-dom
   */
  includeFontScript() {
    const id = 'mimofrontscript'

    if (document.getElementById(id)) {
      // script already loaded by other player
      return
    }

    const linkNode = document.createElement('link')
    linkNode.id = id
    linkNode.type = 'text/css'
    linkNode.rel = 'stylesheet'
    linkNode.href = fontScript
    document.head.appendChild(linkNode)
  }
}

/**
 * Defines the web-component
 */

customElements.define('mimo-player', MimoPlayer)
;(window as any).MimoPlayer = MimoPlayer
